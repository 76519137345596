var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mainBox", { attrs: { mClass: "organizBox" } }, [
    _c(
      "div",
      { staticClass: "wapBox" },
      [
        _c("div", { staticClass: "flexs left" }, [
          _c("div", { staticClass: "l_title" }, [
            _c("div", { staticClass: "w50" }, [_vm._v("设备分类筛选")]),
          ]),
          _c("div", { staticClass: "treeWarp" }, [
            _c(
              "div",
              { staticClass: "pd16" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: _vm.filterText,
                    callback: function ($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "scrollBox" },
              [
                _c("el-tree", {
                  ref: "myTree",
                  attrs: {
                    data: _vm.treeData,
                    "filter-node-method": _vm.filterNode,
                    "expand-on-click-node": false,
                    props: _vm.treeProps,
                    "default-expand-all": "",
                  },
                  on: { "node-click": _vm.nodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ node, data }) {
                        return _c(
                          "div",
                          {
                            class: {
                              txBlue:
                                _vm.actvedNode &&
                                data.projectId == _vm.initTbPid,
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(data[_vm.treeProps.label]) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        )
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "flexs right" }, [
          _c(
            "div",
            { staticClass: "countNodes" },
            _vm._l(_vm.deptNumsArr, function (it, dex) {
              return _c("div", { staticClass: "fxItem" }, [
                _c("div", { staticClass: "_colors", class: "_bgcolor" + dex }, [
                  _vm._v(_vm._s(it.narrow)),
                ]),
                _c("div", { staticClass: "_nams txNowrap" }, [
                  _vm._v(_vm._s(it.name)),
                ]),
                _c("div", { staticClass: "_nums" }, [
                  _vm._v(_vm._s(it.count) + " "),
                  _c("span", { staticClass: "txGray" }, [_vm._v("个")]),
                ]),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "eBtns" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "plainBtn",
                          attrs: { size: "small", plain: "", type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.addEditCopy("add")
                            },
                          },
                        },
                        [_vm._v("新增设备")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "plainBtn",
                          attrs: { size: "small", plain: "", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.tbRowDel()
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "plainBtn",
                          attrs: { size: "small", plain: "", type: "primary" },
                          on: { click: _vm.toDownTemplate },
                        },
                        [_vm._v("导入模版")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "plainBtn",
                          attrs: { size: "small", plain: "", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.upFiles.isOpen = true
                            },
                          },
                        },
                        [_vm._v("导入")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "plainBtn",
                          attrs: { size: "small", plain: "" },
                          on: { click: _vm.refreshTable },
                        },
                        [_vm._v("刷新")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tbBox" },
            [
              _c("TTable2", {
                ref: "refTable",
                attrs: {
                  checkBox: "",
                  tbloading: _vm.tbloading,
                  border: false,
                  showQuery: _vm.rowQuery,
                  tableData: _vm.tableData,
                  tableTitle: _vm.tableTitle,
                },
                on: { rowSeach: _vm.rowSeach },
                scopedSlots: _vm._u([
                  {
                    key: "rowImg",
                    fn: function (scope) {
                      return [
                        _c("TImgVideo", {
                          attrs: {
                            model: scope.row.imgResource,
                            itWidth: "40px",
                            itHeight: "40px",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "tbCode",
                    fn: function (scope) {
                      return [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v(_vm._s(scope.row[scope.column.property])),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "statusStr",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "tx_s",
                            class: "txColor" + scope.row.endingStatus,
                          },
                          [_vm._v(" 闲置")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "operating",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.addEditCopy("edit", scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.tbRowDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.addEditCopy("copy", scope.row)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.tbRowPrint(scope.row)
                              },
                            },
                          },
                          [_vm._v("打印")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("TPagination", {
                on: { initTable: _vm.initTable },
                model: {
                  value: _vm.tableParams,
                  callback: function ($$v) {
                    _vm.tableParams = $$v
                  },
                  expression: "tableParams",
                },
              }),
            ],
            1
          ),
        ]),
        _vm.deviceInfo.open
          ? _c("AddEditDevice", {
              attrs: {
                isOpen: _vm.deviceInfo.open,
                openType: _vm.deviceInfo.type,
                dataId: _vm.deviceInfo.dataId,
              },
              on: { close: _vm.closeEditDialog },
            })
          : _vm._e(),
        _c("TPrintCodes", {
          attrs: { isOpen: _vm.printInfo.open, datas: _vm.printInfo.arrs },
          on: {
            close: function ($event) {
              _vm.printInfo.open = false
            },
          },
        }),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.upFiles.isOpen, width: "40%" },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.upFiles, "isOpen", $event)
              },
            },
          },
          [
            _c(
              "template",
              { slot: "title" },
              [_c("title-icon"), _vm._v(_vm._s(_vm.$t("commons.uploadFile")))],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c("upload", {
                  attrs: {
                    ulType: _vm.upFiles.uploadType,
                    excelType: _vm.upFiles.excelType,
                    rootPath: _vm.upFiles.rootPath,
                  },
                  on: {
                    success: function ($event) {
                      _vm.upFiles.isOpen = false
                    },
                    fail: _vm.uploadFail,
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }