<template>
	<!--设备台账-->
	<mainBox mClass='organizBox'>
		<div class="wapBox">
			<div class="flexs left">
				<div class="l_title">
					<div class="w50">设备分类筛选</div>
				</div>
				<div class="treeWarp">
					<div class="pd16">
						<el-input placeholder="请输入" v-model="filterText"></el-input>
					</div>
					<div class="scrollBox">
						<el-tree ref='myTree' :data="treeData" :filter-node-method="filterNode" :expand-on-click-node='false' :props="treeProps" @node-click='nodeClick' default-expand-all>
							<div :class="{txBlue:actvedNode&&data.projectId==initTbPid}" slot-scope="{ node, data }">
								{{data[treeProps.label]}}
							</div>
						</el-tree>
					</div>
				</div>
			</div>
			<div class="flexs right">
				<div class="countNodes">
					<div class="fxItem" v-for="(it,dex) in deptNumsArr">
						<div class="_colors" :class="'_bgcolor'+dex">{{it.narrow}}</div>
						<div class="_nams txNowrap">{{it.name}}</div>
						<div class="_nums ">{{it.count}} <span class="txGray">个</span></div>
					</div>
				</div>
				<div class="eBtns">
					<el-row>
				      	<el-col :span="24">
				      		<el-button class='plainBtn' size="small" plain type='success' @click='addEditCopy("add")'>新增设备</el-button><!-- 导出 -->
				      		<el-button class='plainBtn' size="small" plain type='danger'  @click='tbRowDel()'>删除</el-button><!-- 导出 -->
				        	<el-button class='plainBtn' size="small" plain type='primary' @click='toDownTemplate'>导入模版</el-button><!-- 导出 -->
				        	<el-button class='plainBtn' size="small" plain type='primary' @click='upFiles.isOpen = true'>导入</el-button><!-- 导出 -->
				        	<el-button class='plainBtn' size="small" plain @click="refreshTable">刷新</el-button><!-- 刷新 -->
				      	</el-col>
			    	</el-row>
				</div>
				<div class="tbBox ">
					<TTable2  ref="refTable" checkBox :tbloading='tbloading'  :border='false' :showQuery='rowQuery' @rowSeach='rowSeach' :tableData="tableData" :tableTitle="tableTitle">
						
						<template slot='rowImg' slot-scope="scope">
							<TImgVideo :model='scope.row.imgResource' itWidth='40px' itHeight='40px'/> 
						</template>
						<template slot='tbCode' slot-scope="scope">
							<el-button type="text" >{{scope.row[scope.column.property]}}</el-button>
						</template>
						<template slot='statusStr' slot-scope="scope">
							<span class="tx_s" :class="'txColor'+scope.row.endingStatus"> 闲置</span>
						</template>
						<template slot='operating' slot-scope="scope">
							<el-button type="text" @click='addEditCopy("edit",scope.row)'>编辑</el-button>
							<el-button type="text" @click='tbRowDetail(scope.row)'>详情</el-button>
							<el-button type="text" @click='addEditCopy("copy",scope.row)'>复制</el-button>
							<el-button type="text" @click='tbRowPrint(scope.row)'>打印</el-button>
						</template>
					
					</TTable2>
					<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
				</div>
			</div>
			<AddEditDevice v-if='deviceInfo.open' :isOpen='deviceInfo.open' :openType='deviceInfo.type' @close='closeEditDialog' :dataId='deviceInfo.dataId' />
			<TPrintCodes :isOpen="printInfo.open"  @close="printInfo.open = false" :datas="printInfo.arrs"/>
			<!--导入文件-->
	    	<el-dialog :visible.sync="upFiles.isOpen" width="40%">
		      	<template slot="title"><title-icon />{{ $t("commons.uploadFile") }}</template>
		      	<div slot="footer">
			        <upload
			            :ulType="upFiles.uploadType"
			            :excelType="upFiles.excelType"
			            :rootPath="upFiles.rootPath"
			            @success="upFiles.isOpen=false"
			            @fail="uploadFail"
			        ></upload>
		      	</div>
		    </el-dialog>
		</div>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import AddEditDevice from './model/AddEditDevice.vue'
	import TImgVideo from '@/components/YTable/TImgVideo.vue';
	import { envInfo } from "@/constants/envInfo";
	import Upload from "@/components/Upload";
	import TPrintCodes from "@/components/YTable/TPrintCodes.vue";
	import {downDeviceTemplate,getLoopUpItem,batchDeleteDevice,findDeviceById} from "@/api/business/base/tenant/device";
	const statusArrs=[
		//入驻状态 0待审核 1待退租 2待回执 3待结算 4已退租 5已驳回
		{label:'待审核',value:0},
		{label:'待使用',value:1},
		{label:'待回执 ',value:2},
		{label:'待结算 ',value:3},
		{label:'使用中',value:4},
		{label:'已驳回',value:5},
	]
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable2,TPagination,TImgVideo,AddEditDevice,Upload,TPrintCodes},
		data() {
			return {
				upFiles:{
					isOpen:false,
					uploadType: "ExcelImport",
					excelType:"ruge.device",
      				rootPath: envInfo.bgApp.basePath,
				},
				deptNumsArr:[
					{name:'工程部设备数',count:'0',narrow:'工'},
					{name:'信息部设备数',count:'0',narrow:'信'},
					{name:'安管部设备数',count:'0',narrow:'安'},
				],
				printInfo: {//打印
			        open: false,
			        arrs: [],
		      	},
				filterText: '',
				treeData:[],
				initTbPid:0,//查询table数据需要的项目id
				treeProps:{
					label: 'projectName',
					children: 'projectChildren',
				},
				tableTitle: [
					{
						name: '图片',
						prop: "imgs",
						width: "100",
						slotName: "rowImg",
					},
					{
						name: '资产编号',
						prop: "assetNo",
						width: "140",
						queryInfo: {type: 'input',value: ""},
					},
					{
						name: '设备名称',
						prop: "deviceName",
						width: "140",
						queryInfo: {type: 'input',value: ""},
					},
					{
						name: '设备编号',
						prop: "factoryCode",
						width: "140",
						queryInfo: {type: 'input',value: ""},
					},
					{
						name: '设备类型',
						prop: "categoryName",
						seachProp:'categoryId',
						width: "140",
						queryInfo: {
							type:'select',
							value: "",
							options:[],
						}
					},
					{
						name: '设备型号',
						prop: "assetModel",
						width: "140",
						queryInfo: {type: 'input',value: ""},
					},
					{
						name: '安装位置',
						prop: "positionName",
						seachProp:'positionId',
						width: "140",
						queryInfo: {
							type: 'cascader',options:[],value: "",
							checkProps:{ emitPath:false,multiple: false,value:'positionId',label:'positionName',children:'children',checkStrictly:false},
						},
					},
					{
						name: '关联企业',
						prop: "enterpriseName",
						seachProp:'enterpriseId',
						width: "140",
//						slotName: "tbCode",
						queryInfo: {type: 'select',options:[],value: ""},
					},
					{
						name: '责任部门',
						width: "140",
						nameToId:true,
						prop: "dutyDeptName",
						seachProp:'dutyDeptId',
						queryInfo: {type: 'select',options:[],value: ""},
					},
					{
						name: '责任人',
						width: "140",
						nameToId:true,
						prop: "repairPersonName",
						seachProp:'repairPersonId',
						queryInfo: {type: 'employe',value: ""},
					},
					{
						name: '供应商',
						width: "140",
						prop: "supplierName",
						queryInfo: {type: 'input',value: ""},
					},
					{
						name: '联系方式',
						prop: "contactNo",
						width: "140",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '备注',
						prop: "description",
						width: "140",
					},
//					{
//						name: '状态',
//						width:150,
//						prop: "statusStr",
//						slotName:'statusStr',
//						queryInfo: {
//							type:'select',
//							value:'',
//							options:statusArrs,
//						}
//					},
					{name: '操作',width: "180",fixed: 'right',slotName: "operating"},
				],
				tableData: [],
				deviceInfo:{//编辑设备的弹框信息
		      		open:false,
		      		type:'add',
		      		dataId:'',
		      	},
		      	actvedNode:{
		      		parentName:''
		      	},//选中的项目节点  用于查询table数据
		      	operateRow:{},
		      	isTbAll:false,
		      	searchPhrase:'',
		      	rowDatas:{},//编辑人员时候操作的行数据
		      	deviceNamesObj: {}, //设备类型名称集合
			};
		},
		created() {},
		mounted() {
			this.initTreeDatas();
			this.initCountNums();
			this.openEditByCode();
		},
		watch: {
			filterText(val) {
				this.$refs.myTree.filter(val);
			}
		},
		computed: {},
		methods: {
			initTbSeachOptions(){
				this.findDeviceType();
				this.initDeptList();
				this.initSystemClassify();
				this.initPositionTree();
				this.initEmployees();
				this.initEnterpriseList();
			},
			tbRowDel(row){
				let params={
					deviceIds: '',
				}
				let Arrs=this.$refs.refTable.selection;
				if(Arrs){
					let ids=[];
					Arrs.map(it=>ids.push(it.deviceId));
					params.deviceIds=ids.join(",");
				}
				if(!params.deviceIds){
					this.$message('请选择数据')
				 	return;
				}
				console.log('tbRowDel-params',params)
				this.$confirm('是否确认删除', '提示').then(() => {
				 	batchDeleteDevice(params).then(() => {
		              	this.$message({
	              	 		type: "success",
		                	message: this.$t("message.deleteSuccess"),
		              	});
		              	this.initTable();
		            });
					
				}).catch(() => {});
			},
			
			async initCountNums(){
				
				let params={
					isloading:false
				}
				let res=await this.ApiHttp('//base/tenant/device/findDeviceNumGroupByDept',params,'get');
				console.log('initCountNums',res)
				if(res){
					res.map(it=>{
						it.name=it.dutyDeptName;
						it.count=it.deviceNum;
						it.narrow=it.dutyDeptName.split('')[0];
					})
					this.deptNumsArr={...res};
				}
//				res.map(it=>{
//					it.label = it.categoryName;
//					it.value = it.categoryId;
//				})
//				this.common.injectionOption2('deviceClass',res,this.tableTitle)
				
				
				
			},
			
			toDownTemplate() {
		      	downDeviceTemplate();
		    },
		    uploadFail() {
	      		this.$message({ type: "error", message: this.$t("message.uploadFial") });
		    },
		    openEditByCode(){
		    	const {deviceCode} = this.$route.query;
//		    	const deviceCode='GS2-Philips-198';
				if(!deviceCode) return
		    	this.tableTitle.map(it=>{
		    		if(it.prop=='factoryCode'){
		    			it.queryInfo.value=deviceCode
		    		}
		    	})
				this.$nextTick(function(){
					this.ApiHttp('/base/tenant/device/getByCode?factoryCode='+deviceCode).then(res=>{
						if(res){
//								console.log('132465res',res,this.treeData)
							this.addEditCopy("edit",res);
							for(let it of this.treeData){
								if(it.projectChildren){
									for(let i=0;i<it.projectChildren.length;i++){
										let item=it.projectChildren[i]
										if(item.projectId==res.projectId){
//												console.log('888888888',item,res.projectId)
											this.nodeClick(item);
											break;
										}
									}
								}
							}
						}
					})
				})
		    },
			addEditCopy(type,row){
				let rowId=type=='add'?'':row.deviceId;
				this.deviceInfo={//编辑设备的弹框信息
		      		open:true,
		      		type:type,
		      		dataId:rowId,
	      		};
	      		console.log('addEditCopy----',type)
			},
			tbRowDetail(row){
      			let urlData={
      				deviceId:row.deviceId,
      				deviceNamesObj:this.deviceNamesObj,
      				factoryCode:row.factoryCode,
      				productKey:row.productKey,
      				rlinkDeviceName:row.rlinkDeviceName,
      			}
				let params = {
			        path: "/rwork/deviceLedger/ledgerDetail",
			        query: urlData,
			      };
	      		this.$router.push(params);
			},
			tbRowPrint(row) {
				//打印
				let dataList=[];
				if(row){
					dataList.push(row);
				}else{
					dataList=this.$refs.refTable.selection;
				}
		      	if (dataList.length < 1) {
			        this.$message({
			          	type: "info",
			          	message: this.$t("commons.multipleSelectionOne"),
		        	});
		        	return;
		      	} else {
		      		let Arrs = [];
			        dataList.map((it) => {
			          	let objs = {
			            	name: it.deviceName,
			            	qrText: `{"type":"DEVICE","deviceId":` + it.deviceId + `}`,
			          	};
			          	Arrs.push(objs);
			        });
			        this.printInfo = {
			          	open: true,
			          	arrs: Arrs,
			        };
		      	}
		    },
			closeEditDialog(isInit){
				//关闭项目弹框
				this.deviceInfo={
		      		open:false,
		      		dataId:'',
		      	};
		      	if(isInit){
		      		this.initTable();
		      	}
			},
			exportEmp() {
				const params = {
					searchPhrase:this.searchPhrase,
	      			...this.seachData,
					...this.tableParams,
	      		};
	      		console.log('23232',params)
		      	this.ApiHttp('/organization/tenant/dept/list/tree',params,'GET');(params).then((msg) => {
		          	this.$message({
		            	type: "success",
		            	message: this.$t("message.operationSuccess"),
		          	});
		          	let exportObj = {
		            	taskId: msg,
		            	taskName: "Employee",
		            	taskStatus: 0,
		            	rootPath: "organizationPath",
		          	};
		          	//将导出任务丢入导出任务列表中
		          	store.dispatch("PushExportNotice", exportObj);
		        }).finally(() => {});
		    },
			nodeClick(node) {
				
				if(node.projectId){
					this.actvedNode={...node};
					this.initTbPid=node.projectId;
					this.initTbSeachOptions();
					this.initTable();
				}else{
//					this.actvedNode=null;
				}
			},
			filterNode(value, data) {
				if(!value) return true;
				return data[this.treeProps.label].indexOf(value) !== -1;
			},
			async initPositionTree(){
				let res=await this.ApiHttp('/base/tenant/position/findPositionTree?projectId='+this.initTbPid);
				if(res){
					let positionArrs = this.common.listToTree(res, "positionId", "parentId");
					console.log('this.positionArrs',positionArrs)
					this.common.injectionOption2('positionName',positionArrs,this.tableTitle)
				}
			},
			
			async initEmployees(){
//				await this.$store.dispatch('getEmployeeDatas');
//				let Arrs=this.$store.state.app.employeeArrs;
//				console.log('initEmployees',Arrs)
//				this.common.injectionOption2('repairPersonName',res,this.tableTitle)
			},
			findDeviceType() {
		      getLoopUpItem({ classifyCode: "DEVICE_TYPE" }).then((res) => {
		        res.map((it) => (this.deviceNamesObj[it.itemCode] = it.itemName));
		        this.deviceType = res;
		      });
		    },
		    
		    async initEnterpriseList(projectId){
      			//获取企业列表
		      	let params = {
			        projectId: this.initTbPid,
		      	};
		      	let res = await this.ApiHttp("/organization/enterprise/list", params);
		      	if (res) {
			        res.map((it) => {
			          it.label = it.enterpriseName;
			          it.value = it.enterpriseId;
			        });
		      	}
		      	this.common.injectionOption2('enterpriseName',res,this.tableTitle)
			},
			async initDeptList(projectId){
				let res=await this.ApiHttp('/base/tenant/project/findDeptListByProject?projectId='+this.initTbPid);
				if(res){
					res.map(it=>{
						it.label = it.deptName;
						it.value = it.deptId;
					})
					this.common.injectionOption2('dutyDeptName',res,this.tableTitle)
				}
			},
			async initSystemClassify(){
				let params={
					isloading:false
				}
				let res=await this.ApiHttp('/base/tenant/category/list',params,'get');
//				let flArr = this.common.listToTree(res, "categoryId", "parentId");
				res.map(it=>{
					it.label = it.categoryName;
					it.value = it.categoryId;
				})
				this.common.injectionOption2('categoryName',res,this.tableTitle)

			},
			async initTreeDatas(){
				//获取公司和项目数据
				let params={
					isloading:false
				}
				let res = await this.ApiHttp('/base/tenant/project/findCompanyProjectList',params,'GET');
				if(res){
					res.map((it,dex)=>{
						it.projectName=it.companyName;
						if(dex<1&&it.projectChildren){
							if(it.projectChildren.length>0){
								this.nodeClick(it.projectChildren[0]);
								this.$nextTick(()=>{
									//默认选中第一个
									setTimeout(()=>{
										let ele=$(".el-tree-node__children .el-tree-node.is-expanded:first").addClass('dfNode1');
									},200)
									
								})
							}
						}
					})
					this.treeData=res||[];
				}
			},
			
			async initTable(){
				if(!this.initTbPid) return;
				this.tbloading=true;
				let params={
					isloading:false,
					projectId:this.initTbPid,
					searchPhrase:this.searchPhrase,
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
					...this.rowFilterData,
				}
				for(let it of this.tableTitle){
					if(it.seachProp){
						params[it.seachProp]=params[it.prop];
						delete params[it.prop];
					}
				}
				
				if(this.initTbPid<1){
					
					delete params.projectId;
					params.companyId=this.actvedNode.companyId;
				}
				
				let res = await this.ApiHttp('/base/tenant/device/page',params);
				this.isTbAll=false;
				console.log('55555555',res)
				res.rows.map(it=>{
					if(it.price==0){
						it.price='0'
					}
					if(it.imageId){
						it.imgResource=[
							{
								contentType: "image/jpeg",
								fileId: it.imageId,
								fileType: "image/jpeg",
								thumbnailId: null,
							}
						]
					}
					
					
				})
				this.tableData = res.rows||[];
				this.tableParams.total = res.total;
				this.$nextTick(()=>{
	  				this.tbloading=false;
	  			})
			},
			
			
			//公司相关 end
		},
	};
</script>

<style scoped lang="scss">

	.wapBox{
		height: 100%;
		display: flex;
		
		.flexs{
			height: 100%;
		}
		.left{
			width: 320px;
			border-right: 1px solid #EEEEEE;
		}
		.l_title{
			padding: 12px 0;
			border-bottom: 1px solid #EBEEF5;
			color: #2F3941;
			display: flex;
			.w50{
				font-weight: 600;
				width: 50%;
				padding: 0 16px;
				line-height: 36px;
			}
			.evts{
				cursor: pointer;
				font-weight: 400;
				text-align: right;
				color: #4E5969;
			}
		}
		.treeWarp{
			padding: 12px 16px;
			box-sizing: border-box;
			height: calc(100% - 62px);
    	overflow-y: auto;
		}
		.right{
			width: calc(100% - 320px);
			padding:16px 24px;
			.countNodes{
				display: flex;
				.fxItem{
					flex: auto;
					margin-right: 20px;
					background: red;
					align-content: center;
					align-items: center;
					display: flex;
					height: 92px;
					padding: 0 26px;
					background: #FFFFFF;
					box-shadow: 2px 4px 12px 0px rgba(0,0,0,0.12);
					border-radius: 4px;
					._nams,._nums{
						flex: auto;
						font-weight: bold;
						font-size: 16px;
					}
					._colors{
						width: 28px;
						height: 28px;
						border-radius: 50%;
						background: #F4F6FE;
						color: #1A4CEC;
						font-size: 15px;
						font-weight: bold;
						text-align: center;
						line-height: 28px;
					}
					/*._bgcolor0{
						background: rgb(27,76,236);
					}
					._bgcolor2{
						background: rgb(232,51,28);
					}*/
					._nams{
						font-size: 18px;
						color: #000000;
						padding-left: 12px;
					}
					._nums{
						text-align: right;
						font-size: 30px;
						color: #000000;
						font-weight: bold;
						.txGray{
							color: #909399;
							font-size: 14px;
							font-weight: 400;
							position: relative;
							top: -1px;
						}
					}
				}
				
				.fxItem:last-child{
					margin-right: 0;
				}
			}
			.c_name{
				padding: 10px 0;
				font-size: 14px;
				color: #8993A4;
			}
			.p_name{
				color: #2F3941;
				font-size: 18px;
				font-weight: bold;
				padding: 8px 0 24px 0;
			}
			.eBtns{
				margin-top: 24px;
				.el-button{
				    padding: 10px 18px;
				}
			}
			.tbBox{
				margin-top: 12px;
				position: relative;
				.diyCkWarp{
					position: absolute;
					top: 20px;
					left: 24px;
				}
				.tx_s{
					padding: 8px;
					background: #F4F6FE;
					border-radius: 2px;
					color: #FA8543;/*#4374FA;*/
				}
			}
		}
		
		.tbTreeWarp{
			margin-top: 4px;
			height: 598px;
			overflow-y: auto;
			
		}
		.tbTreeWarp.tbTreeWarp2{
			height: 540px;
		}
		.diyTbTree{
			width: 100%;
			.treeNode {
			    flex: 1;
			    display: flex;
			    align-items: center;
			    justify-content: space-between;
			    font-size: 14px;
			    padding-right: 8px;
			  }
			.n_name{
				display: inline-block;
				width: calc(100% - 160px);
			}
			.n_btns{
				display: inline-block;
				width: 160px;
			}
			.treeNode{
				
			}
		}
	}
</style>
<style type="text/css">
	.treeWarp {
		.el-tree-node__content {
			height: 40px;
			width: 100%;
			position: relative;
			._edicon,._delicon{
    			cursor: pointer;
    			position: absolute;
    			right: 6px;
    			font-size: 18px;
    			top: 8px;
    			display: none;
    		}
    		._delicon{
    			position: absolute;
    			right: 26px;
    		}
		}
		.el-tree-node__content:hover{
			._edicon,._delicon{
				display: block;
			}
		}
	}
	.tbTreeCheck thead .el-checkbox{
		display: none;
	}
	.organizBox .m-body{
		padding: 0 !important;
	}
	.diyTbTree{
		.el-tree-node__content{
		    height: 48px;
			border-bottom: 1px solid #EBEEF5;
		}
	}
	.treeWarp{
		.el-tree-node__content{
		    height: 40px;
		}
	}
	.dfNode1{
		.el-tree-node__label{
			color: #1A4CEC;
		}
	}
	.drBtn.el-button--text{
			color:#2F3941 !important;
		}
</style>