<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="nameByType" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div class="scollWarp">
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="upImgs" slot-scope="scope">
						<div class="txl24">
							温馨提示：最多上传1张图片，图片大小不超过2M，图片格式为jpg，png。</br>图片尺寸  335*225 像素
						</div>
						<TImgVideo :model='htmlDatas[scope.current.keys]||[]' itWidth='120px' itHeight='120px' allowAdd :maxlimt='1'/> 
					</template>
					
					<template slot="treeSelect" slot-scope="scope">
						<tree-select
			                  v-model="htmlDatas[scope.current.keys]"
			                  :disable-branch-nodes="true"
			                  :multiple="false"
			                  :show-count="true"
			                  :beforeClearAll="clearPositionId"
			                  :placeholder="$t('commons.selectPlease')"
			                  :noOptionsText="$t('base.category.notConfigured')"
			                  :options="positionArrs"
			                  @select="checkPositionId"
			                />
						
						
					</template>
					
					
					<template slot="tagsArrs" slot-scope="scope">
						<div style="display: inline-block;">
							<TagBind :tagValueList="htmlDatas[scope.current.keys]||[]" tagType :notAuto='true' :limit='10' :maxlength='6' addName='添加标签'/>
						</div>
					</template>
					<template slot="setMarker" slot-scope="scope">
						<el-input v-model="latObj.lng" style='width: 50%;' placeholder="经度"  @change='inplatChange' oninput="value=value.replace(/[^\d^\.]+/g,'')"/>
        		 		<el-input v-model="latObj.lat" style='width: 50%;' placeholder="纬度" @change='inplatChange'oninput="value=value.replace(/[^\d^\.]+/g,'')" >
        		 			<el-button slot="append" @click="openMkMap=true" icon="el-icon-location" ></el-button>
		              	</el-input>
					</template>
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 保存</el-button>
		        </div>
			</div>
		</el-dialog>
		<markerByMap v-if='openMkMap' :isOpen='openMkMap' @close='closeMkMap' @mkSave='keepMapMaker' :lnglats='latObj'></markerByMap>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TImgVideo from '@/components/YTable/TImgVideo.vue';
	import TagBind from "@/components/TagBind";
	import markerByMap from "@/views/business/base/position/tenant/markerByMap.vue";
	import TForm from '@/components/YTable/TForm.vue';
	import TreeSelect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			openType:{
				type: String,
				default: 'add',
			},
		},
		components: {TForm,TagBind,TImgVideo,markerByMap,TreeSelect},
		data() {
			return {
				nameByType:'新增设备',
				nameObjs:{
					add:'新增设备',
					edit:'编辑设备',
					copy:'复制设备',
				},
				htmlDatas:{
					location:'',
	  			},
	  			latObj:{lat:'',lng:'',},
	  			isHttping:false,
  			 	openMkMap:false,
	  			formlist:[
	  				{name: '设备名称',keys: 'deviceName',value: '',type: 'input',isMust:true,inputMethod:this.changedeviceName,maxlen:50,flex:12},
	  				{name: '设备编码',keys: 'factoryCode',value: '',type: 'input',isMust:true,trigger: ["blur", "change"],flex:12},
	  				{name: '所属项目',keys: 'projectId',value: '',type: 'select',options:[],flex:12,isMust:true,changMethod:this.changeProject},
	  				{name: '安装位置',keys:'positionId',value:null,type:'slot',slotName:"treeSelect",flex:12,},
	  				{name: '设备类型',keys: 'categoryId',value: '',type: 'select',options:[],flex:12,isMust:true},
//	  				{name: '设备型号',keys: 'assetModel',value: '',type: 'select',options:[],flex:12,isMust:true},
	  				{name: '资产编码',keys: 'assetNo',value: '',type: 'input',flex:12},
	  				{name: '关联企业',keys: 'enterpriseId',value: '',type: 'select',options:[],flex:12},
	  				{name: '责任部门',keys: 'dutyDeptId',value: '',type: 'select',options:[],flex:12,isMust:true},
	  				{name: '负责人',keys: 'repairPerson',value: '',type: 'employe',flex:12,isMust:true},
	  				{name: '供应商',keys: 'supplierName',value: '',type: 'input',flex:12},
	  				{name: '联系方式',keys: 'contactNo',value: '',type: 'input',flex:12},
	  				{name: '备注',keys: 'description',value: '',type: 'input',flex:12},
//	  				{name: '上传图片',keys: 'imgs',value: [],type: 'input',disabled:true,flex:12},
	  				{name: '上传图片',keys:'upImgs',value:[],type:'slot',slotName:"upImgs"},
	  				{name: '设备标签',keys:'tagList',value:[],type:'slot',slotName:"tagsArrs"},
	  				{name: '经纬度',keys:'location',value:'',type:'slot',slotName:"setMarker"},
	  			],
	  			positionArrs:[],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			this.initHtml();
			
		},
		mounted() {
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			initHtml(){
				this.resetTFrom();
				this.initProjectArrs();
				this.initSystemClassify();
				this.getDatasById();
				this.nameByType=this.nameObjs[this.openType];
			},
			clearPositionId() {
		      this.htmlDatas.positionId = "";
		      return true;
		    },
		    checkPositionId(val) {
		      this.htmlDatas.positionId = val.positionId;
		    },
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
		    changedeviceName(val) {
		    	// 联动企业名称  生成编码
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.factoryCode=n;
		    },
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			async getDatasById(){
				if(this.dataId){
		  			let res = await this.ApiHttp('/base/tenant/device/get/'+this.dataId);
					if(res){
						res.tagList=res.tagList||[];
						if(res.latitude&&res.longitude){
							this.latObj={
								lat:res.latitude,
								lng:res.longitude,
							}
						}
						if(res.imageId){
							res.upImgs= [
								{
									contentType: "image/jpeg",
									fileId: res.imageId,
									fileType: "image/jpeg",
									thumbnailId: null,
								}]
						}
						this.htmlDatas={...this.htmlDatas,...res}
						this.initPositionTree(this.htmlDatas.projectId);
						this.initEnterpriseList(this.htmlDatas.projectId);
						this.initDeptList(this.htmlDatas.projectId);
						
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					source:'1',
					...this.htmlDatas,
//					imageId:this.htmlDatas.upImgs.id,
				}
				console.log('params',params)
				if(params.upImgs&&params.upImgs.length>0){
					let vImg=params.upImgs[0];
					if(vImg.fileType!=='image/jpeg'){
						this.$message('请选择图片')
						return;
					}
					params.imageId=vImg.fileId;
				}
				let urlType='create'
				if(this.dataId){
					if(this.openType=='edit') urlType='update';//编辑
				}
				let res = await this.ApiHttp('/base/tenant/device/'+urlType,params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			closeMkMap(){
		  		//ych-关闭地图弹窗；
		  		this.openMkMap=false;
		  	},
			keepMapMaker(lnglat){
		  		console.log('keepMapMaker',lnglat)
		  		this.htmlDatas.location = lnglat.lng + "," + lnglat.lat;
		      	this.latObj=lnglat;
		  		this.closeMkMap();
		  	},
		  	inplatChange(){
		  		this.htmlDatas.location = this.latObj.lng + "," + this.latObj.lat;
		  		if(!this.latObj.lng||!this.latObj.lat){
		  			this.htmlDatas.location='';
		  		}
		  	},
		  	async initProjectArrs(){
				let res =await this.$store.dispatch('getAllProjects');
				if(res){
  				res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);
				}
				
			},
			changeProject(val,it){
				console.log(val,it)
				//项目切换
				this.htmlDatas.positionId=null;
				this.htmlDatas.enterpriseId='';
				this.htmlDatas.dutyDeptId='';
				this.initPositionTree(this.htmlDatas.projectId);
				this.initEnterpriseList(this.htmlDatas.projectId);
				this.initDeptList(this.htmlDatas.projectId);
				 
			},
			async initPositionTree(projectId=''){
				let res=await this.ApiHttp('/base/tenant/position/findPositionTree?projectId='+projectId);
				if(res){
					res.map(it=>{
						it.label = it.positionName;
						it.id = it.positionId;
					})
					this.positionArrs = this.common.listToTree(res, "positionId", "parentId");
					this.common.insertOptions(this.formlist,'positionId',this.positionArrs);
					console.log('this.positionArrs',this.positionArrs)
				}
			},
			
			async initSystemClassify(){
				let params={
					isloading:false
				}
				let res=await this.ApiHttp('/base/tenant/category/list',params,'get');
				res.map(it=>{
					it.name = it.categoryName;
					it.code = it.categoryId;
				})
				this.common.insertOptions(this.formlist,'categoryId',res);
			},
			async initEnterpriseList(projectId){
      			//获取企业列表
		      	let params = {
			        projectId: projectId,
		      	};
		      	let res = await this.ApiHttp("/organization/enterprise/list", params);
		      	if (res) {
			        res.map((it) => {
		//	          if (it.enterpriseName.length > 20) {
		//	            it.enterpriseName = it.enterpriseName.slice(0, 20) + "...";
		//	          }
			          it.name = it.enterpriseName;
			          it.code = it.enterpriseId;
			        });
		      	}
		      	this.common.insertOptions(this.formlist,'enterpriseId',res || []);//添加选项数据到所属项目
			},
			async initDeptList(projectId){
				let res=await this.ApiHttp('/base/tenant/project/findDeptListByProject?projectId='+projectId);
				if(res){
					res.map(it=>{
						it.name = it.deptName;
						it.code = it.deptId;
					})
					this.common.insertOptions(this.formlist,'dutyDeptId',res|| []);
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}
.txl24{
	line-height: 20px;
    padding: 30px 0;
    padding-bottom: 0;
    color: #909399;
}
.scollWarp{
	/*max-height: 680px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 6px;*/
}
.vue-treeselect__menu-container {
  z-index: 999999999 !important;
}
</style>
